import * as self from '@/utils/compare'
import { isHospitalization } from '@/utils/medical_payment_helper'

export const compareByKey = (a, b, key) => {
  if (a[key] < b[key]) {
    return 1
  } else if (a[key] > b[key]) {
    return -1
  } else {
    return 0
  }
}

export const compareByKeys = (a, b, compareKeys) => {
  let result = 0
  for (const compareKey of compareKeys) {
    result = self.compareByKey(a, b, compareKey)
    if (result) break
  }
  return result
}

export const compareMPH = (a, b) => {
  // a,b <medicalPayment>
  // 退院 -> 入院中 -> 入院開始
  let result = 0
  result =
    a.startHospitalizationFlg && b.inHospitalFlg
      ? 1
      : a.inHospitalFlg && b.startHospitalizationFlg
      ? -1
      : a.inHospitalFlg && b.endHospitalizationFlg
      ? 1
      : a.endHospitalizationFlg && b.inHospitalFlg
      ? -1
      : b.medicalRecordOriginalId - a.medicalRecordOriginalId
  return result
}

export const makeHIdByMPOId = hospitalizations => {
  let hIdByMPOId = {}
  hospitalizations.forEach(v => {
    const hId = v.id
    const mpOIds = v.medicalPaymentOriginalIds
    mpOIds.forEach(oId => (hIdByMPOId[oId] = hId))
  })
  return hIdByMPOId
}

export const sameH = (a, b, hIdByMPOId) => {
  // a b <medicalPayment>
  // hIdByMPOId <obj> { medicalPaymentOriginalId_1: hospitalizationId_1, ... }
  let result = false
  if (a && b && isHospitalization(a) && isHospitalization(b)) {
    const aHId = hIdByMPOId[a.originalId]
    const bHId = hIdByMPOId[b.originalId]
    if (aHId === bHId) result = true
  }
  return result
}

export const compareKarte = (a, b, aMP, bMP, order, hIdByMPOId) => {
  // a b <obj> { medicalRecordOriginalId, date, startTime, ... }
  // aMP bMP <medicalPayment>
  // order === 0 -> 日時降順
  // hIdByMPOId <obj> { medicalPaymentOriginalId_1: hospitalizationId_1, ... }
  let result = 0
  if (a.date === b.date) {
    if (a.startTime === b.startTime) {
      if (self.sameH(aMP, bMP, hIdByMPOId)) {
        result = self.compareMPH(aMP, bMP)
      } else {
        result = b.medicalRecordOriginalId - a.medicalRecordOriginalId
      }
    } else {
      result = b.startTime < a.startTime ? -1 : 1
    }
  } else {
    result = b.date < a.date ? -1 : 1
  }
  return order === 0 ? result : -result
}

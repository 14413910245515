import * as self from '@/utils/price_calculation'
import { BIG_NUMERIC_REGEX, CONSUMPTION_TAX_RATE } from '@/utils/define'
import moment from 'moment'
import Big from 'big.js'

// アニコムの保険金の算出 ※以下の資料を参考
// 動物病院向けシステム仕様書【202210改訂版】.pdf -> p.8 or p.10
// 2023-09-19_1033 -> 外税計算過程.xlsx
export const calculateInsuranceAnicom = (
  pledgeRate,
  discountRate,
  discountPrice,
  surgeryLimitFlg,
  surgeryCount,
  insuranceDayCount,
  treatmentItems // estimateTreatmentItems or medicalTreatmentItems
) => {
  let totalPrice = 0 // 診療費の総額
  let totalPriceForCovered = 0 // 補償対象診療費の総額
  let taxableTotalPriceForCovered = 0 // 課税対象の補償対象診療費の総額
  let discountForCovered = 0 // 課税前補償対象額分の値引額
  let consumptionTaxForCovered = 0 // 割引値引後の課税前補償対象額の課税額
  let insuranceCoveredPrice = 0 // 課税前補償対象額
  let taxIncludedInsuranceCoveredPrice = 0 // 課税後補償対象額
  let insurancePrice = 0 // 保険金, 保険請求額, 保険負担額

  // 診療費の総額 補償対象診療費の総額 課税対象の補償対象診療費の総額 を算出
  treatmentItems.forEach(item => {
    const subtotal = calculateTreatmentItemSubtotalAnicom(item)
    if (item.insuranceFlg === 1) {
      totalPriceForCovered += subtotal
      if (item.taxExemptFlg === 0) taxableTotalPriceForCovered += subtotal
    }
    totalPrice += subtotal
  })

  // 補償対象診療費の総額が 0 のときは処理を終了する
  if (totalPriceForCovered === 0) {
    return { insurancePrice, taxIncludedInsuranceCoveredPrice }
  }

  // 1. 課税前補償対象診療費分の値引額を算出
  // 2. 小数点以下を切り捨てる
  discountForCovered = bigTimes(
    discountPrice,
    new Big(totalPriceForCovered).div(totalPrice)
  )
  discountForCovered = Math.trunc(discountForCovered)

  // 3. 割引と値引を適用する前の課税前補償対象額を算出
  insuranceCoveredPrice = totalPriceForCovered

  // 割引率が 0-100 以内におさまるように補正
  const correctedDiscountRate =
    discountRate < 0 ? 0 : 100 < discountRate ? 100 : discountRate

  // 4. 課税前補償対象額に割引を適用
  // 5. 小数点以下を切り捨てる
  insuranceCoveredPrice = bigTimes(
    insuranceCoveredPrice,
    new Big(100 - correctedDiscountRate).div(100)
  )
  insuranceCoveredPrice = Math.trunc(insuranceCoveredPrice)

  // 6. 割引適用後の課税前補償対象診療費に値引を適用
  if (0 <= discountForCovered && discountForCovered <= insuranceCoveredPrice) {
    insuranceCoveredPrice = insuranceCoveredPrice - discountForCovered
  } else if (insuranceCoveredPrice < discountForCovered) {
    insuranceCoveredPrice = 0
  }

  // 7. 課税前補償対象額の課税割合を算出
  // 8. 割引値引適用後の課税前補償対象額の課税額を算出
  // 9. 小数点以下を切り捨てる
  consumptionTaxForCovered = bigTimes(
    insuranceCoveredPrice,
    new Big(taxableTotalPriceForCovered).div(totalPriceForCovered),
    new Big(CONSUMPTION_TAX_RATE).div(100)
  )
  consumptionTaxForCovered = Math.trunc(consumptionTaxForCovered)

  // 10. 割引値引適用後の課税前補償対象額に課税額を加算して課税後補償対象額を算出
  taxIncludedInsuranceCoveredPrice =
    insuranceCoveredPrice + consumptionTaxForCovered

  // 11. 課税後補償対象額に支払割合を乗じて保険金を算出
  // 12. 小数点以下を切り上げる
  insurancePrice = bigTimes(
    taxIncludedInsuranceCoveredPrice,
    new Big(pledgeRate).div(100)
  )
  insurancePrice = Math.ceil(insurancePrice)

  // 保険金の支払限度額を算出 insuranceMax
  let dayMax = 0 // 通院 or 入院 の支払限度額
  let surgeryMax = 0 // 手術の支払限度額
  if (pledgeRate === 50) {
    dayMax = 10000
    surgeryMax = surgeryCount > 0 && !surgeryLimitFlg ? 100000 : 0
  } else if (pledgeRate === 70) {
    dayMax = 14000
    surgeryMax = surgeryCount > 0 && !surgeryLimitFlg ? 140000 : 0
  }
  const insuranceMax = insuranceDayCount * dayMax + surgeryMax

  // 保険金に支払限度額を適用
  insurancePrice = insuranceMax < insurancePrice ? insuranceMax : insurancePrice

  return { insurancePrice, taxIncludedInsuranceCoveredPrice }
}

// 診療明細書と見積書の金額詳細（内訳）を作成 ※以下の資料を参考
// petorelu_emr -> new_edit_recipe_detail.js -> recalc_total_score()
// 2023-09-19_1033 -> 外税計算過程.xlsx
export const makePriceDetailAnicom = (
  priceItem, // estimate or medicalPayment
  surgeryCount,
  insuranceDayCount,
  treatmentItems // estimateTreatmentItems or medicalTreatmentItems
) => {
  const {
    applyFlg,
    pledgeRate, // 保険割引率, 保険負担率, 支払割合
    discountRate, // 割引率
    discountPrice, // 値引額
    surgeryLimitFlg
  } = priceItem

  let totalPrice = 0 // 診療費 小計, 診療費の総額
  let taxableTotalPrice = 0 // 課税対象の診療費の総額
  let rateDiscountedPrice = 0 // 割引適用額
  let fixedDiscountedPrice = 0 // 値引適用額
  let consumptionTax = 0 // 消費税, 消費税額
  let taxIncludedPrice = 0 // 請求額, 税込額
  let ownerBill = 0 // 飼主請求額, 飼主負担額

  // 診療費の総額 課税対象の診療費の総額 を算出
  treatmentItems.forEach(item => {
    const subtotal = calculateTreatmentItemSubtotalAnicom(item)
    totalPrice += subtotal
    if (item.taxExemptFlg === 0) taxableTotalPrice += subtotal
  })

  // 診療費の総額が 0 のときは処理を終了する
  if (totalPrice === 0) {
    const priceDetail = {
      totalPrice,
      discountRate,
      rateDiscountedPrice,
      discountPrice,
      fixedDiscountedPrice,
      consumptionTax,
      taxIncludedPrice,
      pledgeRate,
      insurancePrice: 0,
      ownerBill,
      taxIncludedInsuranceCoveredPrice: 0
    }
    return valueToLocaleString(priceDetail)
  }

  // 入力された割引率の確認
  if (!BIG_NUMERIC_REGEX.test(discountRate)) {
    return self.makePriceDetailInputNaN({
      discountPrice,
      discountRate,
      pledgeRate,
      totalPrice
    })
  }

  // 割引率が 0-100 以内におさまるように補正
  const correctedDiscountRate =
    discountRate < 0 ? 0 : 100 < discountRate ? 100 : discountRate

  // 診療費の総額に割引を適用して割引適用額を算出
  rateDiscountedPrice = bigTimes(
    totalPrice,
    new Big(100 - correctedDiscountRate).div(100)
  )
  rateDiscountedPrice = Math.trunc(rateDiscountedPrice)

  // 入力された値引額の確認
  if (!BIG_NUMERIC_REGEX.test(discountPrice)) {
    return self.makePriceDetailInputNaN({
      discountPrice,
      discountRate,
      pledgeRate,
      rateDiscountedPrice,
      totalPrice
    })
  }

  // 割引適用額に値引を適用して値引適用額を算出
  if (discountPrice < 0) {
    fixedDiscountedPrice = rateDiscountedPrice
  } else if (rateDiscountedPrice < discountPrice) {
    fixedDiscountedPrice = 0
  } else {
    fixedDiscountedPrice = rateDiscountedPrice - discountPrice
  }

  // 消費税を算出 小数点以下を切り捨てる
  consumptionTax = bigTimes(
    fixedDiscountedPrice,
    new Big(taxableTotalPrice).div(totalPrice),
    new Big(CONSUMPTION_TAX_RATE).div(100)
  )
  consumptionTax = Math.trunc(consumptionTax)

  // 値引適用額に消費税を加算して請求額を算出
  taxIncludedPrice = fixedDiscountedPrice + consumptionTax

  // 保険関連の金額を算出
  const { insurancePrice = 0, taxIncludedInsuranceCoveredPrice = 0 } = applyFlg
    ? calculateInsuranceAnicom(
        pledgeRate,
        discountRate,
        discountPrice,
        surgeryLimitFlg,
        surgeryCount,
        insuranceDayCount,
        treatmentItems
      )
    : {}

  // 飼主請求額を算出
  ownerBill = taxIncludedPrice - insurancePrice

  const priceDetail = {
    totalPrice,
    discountRate,
    rateDiscountedPrice,
    discountPrice,
    fixedDiscountedPrice,
    consumptionTax,
    taxIncludedPrice,
    pledgeRate,
    insurancePrice,
    ownerBill,
    taxIncludedInsuranceCoveredPrice
    //※バックエンド側のmakePriceDetailAnicom()ではdiscountIpetとexcessIpetも入れているが、フロント側では使用しないので入れていない
  }
  return valueToLocaleString(priceDetail)
}

//アイペットの支払限度額は「請求API_金額項目内訳(標準).xlsx シート1.04、プラン別上限」と
//「保険金請求マニュアル.pdf」 p.40(7-5) 保険金の支払限度額 を参照
export const calculateInsuranceMaxIpet = (
  pledgeRate,
  surgeryCount,
  endHospitalizationFlg,
  insuranceDayCount
) => {
  let dayMax = 0
  let surgeryMax = 0
  //手術の限度回数はどのプランでも2回(うちの子プラス/1か月目は1回だが保険適用できないので考慮しない)なので上限は2にする
  const surgeryCountAfterLimit = surgeryCount > 2 ? 2 : surgeryCount
  //↓pledgeRate === 100の場合(うちの子プラス/1か月目)は保険適用できない仕様にしているので入れていない
  if (pledgeRate === 30) {
    dayMax = 9000
    surgeryMax = surgeryCountAfterLimit > 0 ? 40000 * surgeryCountAfterLimit : 0
  } else if (pledgeRate === 50) {
    dayMax = 12000
    surgeryMax =
      surgeryCountAfterLimit > 0 ? 100000 * surgeryCountAfterLimit : 0
  } else if (pledgeRate === 70) {
    dayMax = endHospitalizationFlg === 1 ? 30000 : 12000
    surgeryMax =
      surgeryCountAfterLimit > 0 ? 150000 * surgeryCountAfterLimit : 0
  }
  return insuranceDayCount * dayMax + surgeryMax
}

//アイペットの計算方法では、数量は小数点第2位まで可、小数点以下第3位以降は切り捨てる。
export const calculateTruncatedAmount = (amount = 0) => {
  const toIntegerAmount = Big(Number(amount))
    .times(Big(100)) //整数にする
    .toNumber()
  const truncatedAmount = Math.trunc(toIntegerAmount) / 100
  return truncatedAmount
}

export const calculateTreatmentItemSubtotalIpet = (
  { unitPrice = 0, discountPrice = 0, discountRate = 0, amount = 0 } = {}
  // estimateTreatmentItem or medicalTreatmentItem
) => {
  // 診療項目内容の値の有効性を確認
  const values = [amount, discountPrice, discountRate, unitPrice]
  const existsNaN = values.some(v => !BIG_NUMERIC_REGEX.test(v))
  if (existsNaN) return NaN
  //1,診療項目（medical_treatment_item 行データ)の「割引反映済」の単価(excel: I41、N41 ※以後excel:は省略)を求める
  // 「仮として小数第１位で四捨五入としているが、アプリ側の仕様に依存する。」とexcelに書かれているため、小数第１位で四捨五入するようにした
  const discountedPrice = unitPrice - discountPrice
  const ratePrice = Big(discountedPrice)
    .times(Big(discountRate / 100))
    .toNumber()
  const discountedUnitPrice = Math.round(discountedPrice - ratePrice)

  //2,診療内容別計(a, J41)を求める、小数は残す。
  //  数量は小数点第2位まで可、小数点以下第3位以降は切り捨て。
  const truncatedAmount = calculateTruncatedAmount(amount)
  const subtotal = Big(truncatedAmount)
    .times(discountedUnitPrice)
    .toNumber()
  return subtotal
}

/*
アイペットの場合の各金額の算出処理。
「ピクオス社（ペトレルプラス）テストケース_スクショ付き」ファイルを元に実装。
https://trello.com/c/ozYXsjpm のカード内に上記ファイルがある。

※以前は「請求API_金額項目内訳(標準).xlsx シート1.04」ファイルを元に実装していましたが、「保険対象額合計」の金額の計算方法が間違っていたため、正しい計算方法に修正された「ピクオス社（ペトレルプラス）テストケース_スクショ付き」ファイルを元に実装するよう変更。上記のtrelloのurlが修正した時のカード。

以前の「請求API_金額項目内訳(標準).xlsx シート1.04」ファイルの計算手順の詳細は、「請求API_金額項目内訳(標準).xlsx_1.04の計算過程詳細.txt」に記載。手順は1~23まで。「保険対象額合計」の金額の計算方法以外は変わっていないため、どのような計算を行っているのかを確認する際に参考になる。
(2023/5/25 teams/「個別レビュー」チャネル/「アイペットの請求額・保険金計算」のレビュー時に上記txtファイルを共有)
*/
export const makePriceDetailIpet = (
  priceItem, // estimate or medicalPayment
  surgeryCount,
  insuranceDayCount,
  treatmentItems // estimateTreatmentItems or medicalTreatmentItems
) => {
  const {
    pledgeRate,
    discountRate,
    discountPrice,
    endHospitalizationFlg
  } = priceItem

  //3,小計(J33)を求める
  // ※小数点以下第1位を四捨五入する(excelでは小数を残しているが、レセプト請求では小数なしで送っていてそれに合わせるため)
  //4,保険対象小計(J34)を求める
  //6,保険対象外小計(J36)を求める

  let totalPrice = 0 // 小計(J33)
  let insuranceCoveredTotalPrice = 0 // 保険対象小計(J34)
  let taxableInsuranceCoveredTotalPrice = 0 // 課税保険対象小計
  let insuranceNotCoveredTotalPrice = 0 // 保険対象外小計(J36)
  let taxableInsuranceNotCoveredTotalPrice = 0 // 課税保険対象外小計
  treatmentItems.forEach(item => {
    const subtotal = calculateTreatmentItemSubtotalIpet(item)
    totalPrice = Big(totalPrice)
      .plus(Big(subtotal))
      .toNumber()
    if (item.insuranceFlg === 1) {
      insuranceCoveredTotalPrice = Big(insuranceCoveredTotalPrice)
        .plus(Big(subtotal))
        .toNumber()
      if (item.taxExemptFlg === 0) {
        taxableInsuranceCoveredTotalPrice = Big(
          taxableInsuranceCoveredTotalPrice
        )
          .plus(Big(subtotal))
          .toNumber()
      }
    } else {
      insuranceNotCoveredTotalPrice = Big(insuranceNotCoveredTotalPrice)
        .plus(Big(subtotal))
        .toNumber()
      if (item.taxExemptFlg === 0) {
        taxableInsuranceNotCoveredTotalPrice = Big(
          taxableInsuranceNotCoveredTotalPrice
        )
          .plus(Big(subtotal))
          .toNumber()
      }
    }
  })
  totalPrice = Math.round(totalPrice)

  // 入力された割引率と値引額の確認
  if (
    !BIG_NUMERIC_REGEX.test(discountRate) ||
    !BIG_NUMERIC_REGEX.test(discountPrice)
  ) {
    return self.makePriceDetailInputNaN({
      discountPrice,
      discountRate,
      pledgeRate,
      totalPrice
    })
  }

  // 税率を100分率から小数割合に変換
  const taxRate = CONSUMPTION_TAX_RATE / 100

  //5,「保険対象 外税」(J35)を求める
  // ※小数点以下第1位を「切り上げ」している。
  const insuranceCoveredConsumptionTax = Math.ceil(
    Big(taxableInsuranceCoveredTotalPrice)
      .times(Big(taxRate))
      .toNumber()
  )

  //7,「保険対象外 外税」(J37)を求める
  // ※小数点以下第1位を「切り上げ」している。
  const insuranceNotCoveredConsumptionTax = Math.ceil(
    Big(taxableInsuranceNotCoveredTotalPrice)
      .times(Big(taxRate))
      .toNumber()
  )

  //8,合計(J38)は使用されていないので算出しない

  //9,全体値引計(E47, 値引額（discount）J57と同義)を求める
  const totalDiscountPrice =
    Big(discountPrice).toNumber() +
    Math.round(
      Big(totalPrice)
        .times(Big(discountRate / 100))
        .toNumber() // => Math.round(totalPrice * (discountRate / 100))
    )

  // 全体値引計が小計を超えないように補正
  const discountIpet =
    0 <= totalDiscountPrice && totalDiscountPrice <= totalPrice
      ? totalDiscountPrice
      : totalPrice < totalDiscountPrice
      ? totalPrice
      : 0

  //10,値引後小計(F47)を求める
  const fixedDiscountedPrice = totalPrice - discountIpet

  //11,割引率(G47)を求める
  // ※小数点以下第5位を四捨五入している。
  const totalDiscountRateNotPercent =
    fixedDiscountedPrice === 0
      ? 0
      : Math.round(
          Big(fixedDiscountedPrice / totalPrice)
            .times(Big(10000))
            .toNumber()
        ) / 10000 // => Math.round((fixedDiscountedPrice / totalPrice) * 10000) / 10000

  //12,対象内値引額(H47)を求める
  const insuranceCoveredDiscountPrice =
    insuranceCoveredTotalPrice === 0
      ? 0
      : Math.round(
          Big(totalDiscountPrice)
            .times(Big(insuranceCoveredTotalPrice).div(Big(totalPrice)))
            .toNumber()
        ) // => Math.round(totalDiscountPrice * (insuranceCoveredTotalPrice / totalPrice))

  // 13,対象内按分率(C52)は使用されていないので算出しない

  //14,C 税額(消費税額（tax）J58)を求める
  // ※小数点以下第1位を四捨五入している。
  const consumptionTax = Math.round(
    Big(insuranceCoveredConsumptionTax + insuranceNotCoveredConsumptionTax)
      .times(Big(totalDiscountRateNotPercent))
      .toNumber()
  )

  //15,D 合計(J59)を求める(全体値引・割引・外税適用後の合計金額)
  const taxIncludedPrice = totalPrice - discountIpet + consumptionTax

  //16,H 保険対象額合計(保険対象診療額（compensable）J63)を求める
  // ※小数点以下第1位を四捨五入している。
  const taxIncludedInsuranceCoveredPrice = Math.round(
    Big(insuranceCoveredTotalPrice)
      .plus(
        Big(insuranceCoveredConsumptionTax).times(
          Big(totalDiscountRateNotPercent)
        )
      )
      .minus(Big(insuranceCoveredDiscountPrice))
      .toNumber()
  ) // => Math.round(insuranceCoveredTotalPrice + insuranceCoveredConsumptionTax * totalDiscountRateNotPercent - insuranceCoveredDiscountPrice)

  //17,G 今回請求額（限度オーバ未反映 J62)を求める（ペトレルプラスの「保険請求額」の部分を指す。但し、この時点では支払限度額を超えている場合もある）
  // ※小数点以下第1位を四捨五入している。
  const insurancePriceBeforeApplyMax = Math.round(
    Big(taxIncludedInsuranceCoveredPrice).times(Big(pledgeRate / 100))
  ) // => Math.round(taxIncludedInsuranceCoveredPrice * (pledgeRate / 100))

  //18,I「保険対象 飼主負担額（限度オーバ未反映 ） J64」を求める
  // 保険対象額合計の中で飼主が負担する金額を求める(つまり保険対象外の金額が入っていない飼主請求額のことを指す）
  const insuranceCoveredOwnerBillBeforeApplyMax =
    taxIncludedInsuranceCoveredPrice - insurancePriceBeforeApplyMax

  //19,F 対象外額(J61)を求める
  const taxIncludedInsuranceNotCoveredPrice =
    taxIncludedPrice - taxIncludedInsuranceCoveredPrice

  //20,E 飼主請求額（限度オーバ未反映 J60）を求める
  const ownerBillBeforeApplyMax =
    taxIncludedInsuranceNotCoveredPrice +
    insuranceCoveredOwnerBillBeforeApplyMax

  //支払限度額(C23)を求める。
  const insuranceMax = calculateInsuranceMaxIpet(
    pledgeRate,
    surgeryCount,
    endHospitalizationFlg,
    insuranceDayCount
  )

  //21,J 限度オーバー額（excess J65）を求める(保険請求額が支払限度額を超えていた時に、オーバーした金額を算出している)
  // 差＞0の場合は差を限度オーバ額とする。負の場合は0とする。
  const excessIpet =
    insurancePriceBeforeApplyMax - insuranceMax > 0
      ? insurancePriceBeforeApplyMax - insuranceMax
      : 0

  //22,K 飼主請求額（billing J66）を求める(オーバーした金額は保険で負担できず、飼主が払う分になるので飼主請求額に入れる)
  const ownerBill = ownerBillBeforeApplyMax + excessIpet

  //23,L 今回請求額/アイペット請求額（insurance J67）を求める(保険請求額のことを指す。支払限度額をオーバーした金額は引く)
  const insurancePrice = insurancePriceBeforeApplyMax - excessIpet

  let priceDetail = {
    totalPrice, // 診療費の合計 ⇒ 3,小計(J33)
    discountRate, // 明細割引
    rateDiscountedPrice: 0, // ※割引適用額はアイペットの計算方法では算出していない
    discountPrice, // 明細値引
    fixedDiscountedPrice, // 値引適用額 ⇒ 10,値引後小計(F47)
    consumptionTax, // 消費税, 消費税額 ⇒ 14,C 税額(消費税額（tax）J58)
    taxIncludedPrice, // 税込額, 請求額 ⇒ 15,D 合計(J59)
    pledgeRate, // 保険割引率, 保険負担率, 支払割合
    insurancePrice, // 保険請求額, 保険負担額 ⇒ 23,L 今回請求額/アイペット請求額（insurance J67)
    ownerBill, // 飼主請求額, 飼主負担額 ⇒ 22,K 飼主請求額（billing J66）
    taxIncludedInsuranceCoveredPrice // 保険補償対象額(税込) ⇒ 16,H 保険対象額合計(保険対象診療額（compensable）J63)
    //※バックエンド側のmakePriceDetailIpet()ではdiscountIpetとexcessIpetも入れているが、フロント側では使用しないので入れていない
  }
  //↓テストで画面で使用していない金額もチェックしたいため、テスト環境では他の変数も返すようにしています
  if (process.env.NODE_ENV === 'test') {
    priceDetail = {
      ...priceDetail,
      insuranceCoveredTotalPrice, // 4,保険対象小計 (J34)
      insuranceNotCoveredTotalPrice, // 6,保険対象外小計(J36)
      insuranceCoveredConsumptionTax, // 5,「保険対象 外税」(J35)
      insuranceNotCoveredConsumptionTax, // 7,「保険対象外 外税」(J37)
      totalDiscountPrice,
      discountIpet, // 9,全体値引計(E47, 値引額（discount）J57と同義)
      totalDiscountRateNotPercent, // 11,割引率(G47)
      insuranceCoveredDiscountPrice, // 12,対象内値引額(H47)
      insurancePriceBeforeApplyMax, // 17,G 今回請求額（限度オーバ未反映 J62)
      insuranceCoveredOwnerBillBeforeApplyMax, // 18,I「保険対象 飼主負担額（限度オーバ未反映 ） J64」
      taxIncludedInsuranceNotCoveredPrice, // 19,F 対象外額(J61)
      ownerBillBeforeApplyMax, // 20,E 飼主請求額（限度オーバ未反映 J60）
      insuranceMax, // 支払限度額(C23)
      excessIpet // 21,J 限度オーバー額（excess J65）
    }
  }
  return valueToLocaleString(priceDetail)
}

export const makeInsuranceType = (
  priceItem, // estimate or medicalPayment
  patient
) => {
  let insuranceType = '' // 保険が未設定の場合、空の文字列を返す
  const { applyFlg, anicomCIdCheckId, ipetCheckId } = priceItem
  const hasCheckId = anicomCIdCheckId > 0 || ipetCheckId > 0
  const isApplying = applyFlg === 1 && hasCheckId
  if (isApplying) {
    if (anicomCIdCheckId > 0) {
      insuranceType = 'anicom'
    } else if (ipetCheckId > 0) {
      insuranceType = 'ipet-docomo' // ドコモも含む
    }
  } else {
    const { insurance } = patient
    if (insurance === 'anicom') {
      insuranceType = 'anicom'
    } else if (insurance === 'ipet' || insurance === 'docomo') {
      insuranceType = 'ipet-docomo'
    }
  }
  return insuranceType
}

export const makePriceDetail = (
  priceItem, // estimate or medicalPayment
  surgeryCount,
  insuranceDayCount,
  treatmentItems // estimateTreatmentItems or medicalTreatmentItems
) => {
  const args = [priceItem, surgeryCount, insuranceDayCount, treatmentItems]
  const priceDetail =
    priceItem.insuranceType === 'ipet-docomo'
      ? makePriceDetailIpet(...args)
      : makePriceDetailAnicom(...args) // 保険が未設定の場合はアニコムの計算方法で行う
  return priceDetail
}

export const makePaymentDetail = (
  medicalPayment,
  getMedicalPaymentHistory,
  getMedicalRecordByOriginalId,
  getPaymentsByMedicalPaymentId
) => {
  let payments = []
  const medicalPaymentHistory = getMedicalPaymentHistory(
    medicalPayment.originalId
  )
  const medicalRecord = getMedicalRecordByOriginalId(
    medicalPayment.medicalRecordOriginalId
  )
  medicalPaymentHistory.forEach(mp => {
    const paymentHistory = getPaymentsByMedicalPaymentId(mp.id)
    if (paymentHistory) {
      payments = payments.concat(paymentHistory)
    }
  })
  payments.sort((a, b) => a.id - b.id)
  const validPayments = payments.filter(
    p => !moment(p.cancelDateTime, 'YYYYMMDDHHmmss').isValid()
  )
  let latestPayDateTime = ''
  let totalCash = 0
  let totalCard = 0
  let totalChange = 0
  validPayments.forEach(payment => {
    if (payment.payDateTime > latestPayDateTime) {
      latestPayDateTime = payment.payDateTime
    }
    totalCash += payment.cash
    totalCard += payment.card
    totalChange += payment.change
  })
  const totalPaidAmount = totalCash + totalCard - totalChange
  const unpaidAmount = medicalPayment.burdenAmount - totalPaidAmount
  const type = medicalPayment.redoFlg
    ? '再会計'
    : validPayments.length === 0
    ? '未会計'
    : unpaidAmount === 0
    ? '会計済'
    : '未収金'
  return {
    payments, // 支払い履歴
    type, // 会計ステータス
    unpaidAmount, // 未払い額
    totalCash, // 現金支払い額
    totalCard, // カード支払い額
    totalChange, // おつり額,
    totalPaymentReceived: totalCash + totalCard - totalChange,
    latestPayDateTime, // 支払い日
    medicalTreatmentDay: medicalRecord?.date, // 診療日
    medicalRecordId: medicalRecord?.id
  }
}

// アニコム系の項目の小計の計算
export const calculateTreatmentItemSubtotalAnicom = ({
  unitPrice = 0,
  amount = 0,
  discountPrice = 0,
  discountRate = 0
} = {}) => {
  // 診療項目内容の値の有効性を確認
  const values = [amount, discountPrice, discountRate, unitPrice]
  const existsNaN = values.some(v => !BIG_NUMERIC_REGEX.test(v))
  if (existsNaN) return NaN
  const subtotal =
    Math.trunc(
      bigTimes(unitPrice, amount, new Big(100 - discountRate).div(100))
    ) - discountPrice
  return subtotal
}

export const calculateTreatmentItemSubtotal = (
  insuranceType,
  treatmentItem // estimateTreatmentItem or medicalTreatmentItem
) => {
  const subtotal =
    insuranceType === 'ipet-docomo'
      ? self.calculateTreatmentItemSubtotalIpet(treatmentItem)
      : self.calculateTreatmentItemSubtotalAnicom(treatmentItem)
  return subtotal
}

const valueToLocaleString = object => {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => [key, value.toLocaleString()])
  )
}

export const bigTimes = (...numbers) => {
  return numbers.reduce((pre, cur) => pre.times(cur), new Big(1)).toNumber()
}

// discountPrice または discountRate が数値ではない場合に金額表の値を算出する関数
export const makePriceDetailInputNaN = ({
  discountPrice, // 値引額
  discountRate, // 割引率
  pledgeRate,
  rateDiscountedPrice = NaN,
  totalPrice
}) => {
  const priceDetail = {
    consumptionTax: NaN,
    discountPrice,
    discountRate,
    fixedDiscountedPrice: NaN,
    insurancePrice: NaN,
    ownerBill: NaN,
    pledgeRate,
    rateDiscountedPrice,
    taxIncludedInsuranceCoveredPrice: NaN,
    taxIncludedPrice: NaN,
    totalPrice
  }
  return valueToLocaleString(priceDetail)
}

import { compareByKeys } from '@/utils/compare'
import { makeRepeatPeriods } from '@/utils/vaccine_utils'
import { stringify } from 'csv-stringify/lib/sync'

export const downloadCsv = (csvStr, fileName) => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const blob = new Blob([bom, csvStr], { type: 'text/csv' })
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(blob, fileName)
  } else {
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = fileName
    anchor.click()
  }
}

// ファミリー動物病院のアニレセクラウドへのデータ移行対応
// 「3. ワクチン接種履歴」の CSV の作成
export const makeVaccinationCsvStr = (
  antibodies,
  antibodyTypes,
  patientVaccinePeriods,
  vaccines
) => {
  const antibodiesMap = new Map(antibodies.map(v => [v.id, v]))
  const antibodyTypesMap = Map.groupBy(
    antibodyTypes,
    ({ patientId, vaccineId }) => `${patientId}-${vaccineId}`
  )
  const patientVaccinePeriodsMap = Map.groupBy(
    patientVaccinePeriods,
    ({ patientId, vaccineId }) => `${patientId}-${vaccineId}`
  )
  const vaccinesMap = new Map(vaccines.map(v => [v.id, v]))
  const vaccinations = antibodyTypes
    .map(antibodyType => {
      const antibody = antibodiesMap.get(antibodyType.antibodyId)
      const datedAntibodyType = { ...antibodyType, date: antibody.date }
      return datedAntibodyType
    })
    .toSorted((a, b) => compareByKeys(a, b, ['date', 'id']))
    .map(datedAntibodyType => {
      const { patientId, vaccineId, date } = datedAntibodyType
      const vaccine = vaccinesMap.get(vaccineId)

      // 「ワクチン次回予定」の設定 nextSchedule
      const key = `${patientId}-${vaccineId}`
      const antibodyTypes = antibodyTypesMap.get(key)
      const patientVaccinePeriods = patientVaccinePeriodsMap.get(key) ?? []
      const sortedPatientVaccinePeriods = patientVaccinePeriods.toSorted(
        (a, b) => (a.startDate < b.startDate ? -1 : 1)
      )
      const periods = makeRepeatPeriods(sortedPatientVaccinePeriods)
      const nextPeriod = periods.find(v => date < v.startDate)
      const nextSchedule = nextPeriod
        ? antibodyTypes
            .toSorted((a, b) => (a.date < b.date ? -1 : 1))
            .some(v => {
              const antibody = antibodiesMap.get(v.antibodyId)
              return nextPeriod.startDate <= antibody.date
            })
          ? ''
          : `${nextPeriod.startDate}-${nextPeriod.endDate}`
        : ''

      const vaccination = {
        patientId,
        vaccineName: vaccine.name,
        date,
        nextSchedule,
        lotNo: datedAntibodyType.lotNo
      }
      return vaccination
    })

  const headerValues = [
    '患者ID',
    'ワクチン接種名',
    'ワクチン接種日付',
    'ワクチン次回予定',
    'ワクチン Lot No'
  ]
  const header = headerValues.join()
  const body = stringify(vaccinations)
  const csvStr = header + '\n' + body
  return csvStr
}

// ファミリー動物病院のアニレセクラウドへのデータ移行対応
// 「4. フィラリア予防履歴」の CSV の作成
export const makeFilariaVaccinationCsvStr = (
  antibodies,
  antibodyTypes,
  patientVaccinePeriods
) => {
  const antibodiesMap = new Map(antibodies.map(v => [v.id, v]))
  const antibodyTypesMap = Map.groupBy(
    antibodyTypes,
    ({ patientId, vaccineId }) => `${patientId}-${vaccineId}`
  )
  const patientVaccinePeriodsMap = Map.groupBy(
    patientVaccinePeriods,
    ({ patientId, vaccineId }) => `${patientId}-${vaccineId}`
  )
  const vaccinations = Array.from(antibodyTypesMap)
    .map(([_, groupedAntibodyTypes]) => {
      const latestDatedAntibodyType = groupedAntibodyTypes
        .map(v => {
          const antibody = antibodiesMap.get(v.antibodyId)
          return { ...v, date: antibody.date }
        })
        .toSorted((a, b) => compareByKeys(a, b, ['date', 'id']))
        .at(0)
      return latestDatedAntibodyType
    })
    .toSorted((a, b) => compareByKeys(a, b, ['date', 'id']))
    .map(latestDatedAntibodyType => {
      const { patientId, vaccineId, date } = latestDatedAntibodyType
      const key = `${patientId}-${vaccineId}`

      // 「フィラリア次回予定日」の設定 nextSchedule
      const patientVaccinePeriods = patientVaccinePeriodsMap.get(key) ?? []
      const sortedPatientVaccinePeriods = patientVaccinePeriods.toSorted(
        (a, b) => (a.startDate < b.startDate ? -1 : 1)
      )
      const periods = makeRepeatPeriods(sortedPatientVaccinePeriods)
      const nextPeriod = periods.find(v => date < v.startDate)
      const nextSchedule = nextPeriod
        ? `${nextPeriod.startDate}-${nextPeriod.endDate}`
        : ''

      const vaccination = { patientId, drugName: '', date, nextSchedule }
      return vaccination
    })

  const headerValues = [
    '患者ID',
    'フィラリア投薬薬品名',
    'フィラリア最終処方日',
    'フィラリア次回予定日'
  ]
  const header = headerValues.join()
  const body = stringify(vaccinations)
  const csvStr = header + '\n' + body
  return csvStr
}

// ファミリー動物病院のアニレセクラウドへのデータ移行対応
// 5．狂犬病予防接種履歴の CSV の作成
export const makeRabiesVaccinationCsvStr = (antibodies, antibodyTypes) => {
  const antibodiesMap = new Map(antibodies.map(v => [v.id, v]))
  const antibodyTypesMap = Map.groupBy(
    antibodyTypes,
    ({ patientId, vaccineId }) => `${patientId}-${vaccineId}`
  )
  const vaccinations = Array.from(antibodyTypesMap)
    .map(([_, groupedAntibodyTypes]) => {
      const latestDatedAntibodyType = groupedAntibodyTypes
        .map(v => {
          const antibody = antibodiesMap.get(v.antibodyId)
          return { ...v, date: antibody.date }
        })
        .toSorted((a, b) => compareByKeys(a, b, ['date', 'id']))
        .at(0)
      return latestDatedAntibodyType
    })
    .toSorted((a, b) => compareByKeys(a, b, ['date', 'id']))
    .map(latestDatedAntibodyType => {
      const { patientId, date } = latestDatedAntibodyType
      const vaccination = {
        patientId,
        vaccinationDate: date,
        registrationDate: '',
        registrationNumber: '',
        vaccinationCertificateNumber: ''
      }
      return vaccination
    })

  const headerValues = [
    '患者ID',
    '狂犬病接種日',
    '狂犬病登録日',
    '狂犬病登録№',
    '狂犬病注射済票№'
  ]
  const header = headerValues.join()
  const body = stringify(vaccinations)
  const csvStr = header + '\n' + body
  return csvStr
}
